<template>
  <RecordViewWrapper>
    <Main>
      <div v-if="dataSource">
        <sdPageHeader :title="i18n.t(`accounts.trading.${$route.params.type}`, 0)">
          <template v-slot:buttons>
            <sdButton size="large" type="success" raised @click="changeCreateTradingAccount">
              <sdFeatherIcons type="plus" size="18" style="margin-right: 5px" />
              {{ i18n.t('start.createTradingAccount') }}
            </sdButton>
          </template>
        </sdPageHeader>
        <div v-if="isLoading && dataSource.length < 4" class="spin">
          <a-spin />
        </div>
        <a-row :gutter="15" v-else>
          <a-col class="w-100" :md="24">
            <sdCards headless>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :pagination="{ pageSize: 10, showSizeChanger: true, hideOnSinglePage: true }"
                  :dataSource="dataSource"
                  :columns="columns"
                >
                  <template #name="record">
                    <router-link :to="'/trading-account/' + record.record.id + '/page/1'">
                      {{ record.record.name }}
                    </router-link>
                  </template>
                  <template #authorization="record">
                    <div class="trading__texts">
                      <TradingAuth :tradingInfo="record.record.mt5_account_data" />
                    </div>
                  </template>
                  <template #status="record">
                    <Status
                      :status="
                        record.record.status != 'pending'
                          ? record.record.contest?.status ?? record.record.status
                          : 'pending'
                      "
                    />
                    <div v-if="record.record.contest" class="table__contest">
                      <TradingContestTag :contest="record.record" />
                    </div>
                  </template>
                  <template #action="record">
                    <div class="action">
                      <ActionButtons
                        :record="record.record"
                        v-model:closingModal="closeTradingModal"
                        @openModal="openModal"
                        @openPublishModal="openPublishModal"
                        @openModalCheck="openModalCheck"
                      />
                    </div>
                  </template>
                </a-table>
              </TableWrapper>
            </sdCards>
            <div style="text-align: right">
              <a-pagination
                v-model:current="tradingAccountsMeta.current"
                :total="tradingAccountsMeta.count"
                @change="onChangePage"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
                </template>
              </a-pagination>
            </div>
          </a-col>
        </a-row>
        <sdModal
          centered
          :title="i18n.t(`start.createTokenAccount`)"
          :visible="visibleCreateTradingAccount"
          :onCancel="changeCreateTradingAccount"
          class="create-account"
          :width="windowSize > 850 ? 350 : 300"
        >
          <CreateTradingModal :currentTradingAcc="$route.params.type" @closeModal="changeCreateTradingAccount" />
        </sdModal>

        <ModalCash :visibleModal="visibleModal" @closeModal="closeModal" />
        <PublishModal v-model:data="publishModal" />
        <CloseTradingModal v-model:data="closeTradingModal" />
        <CheckModal v-model:data="checkModal" />
      </div>
      <div class="loading-data" v-else>
        <a-spin size="large"></a-spin>
      </div>
    </Main>
  </RecordViewWrapper>
</template>
<script>
import { RecordViewWrapper } from './style';
import { Modal } from 'ant-design-vue';
import { Main, TableWrapper } from '../styled';
import { useStore } from 'vuex';
import { computed, ref, onMounted, defineAsyncComponent, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const TradingAuth = defineAsyncComponent(() => import('@/components/elements/TradingAuth.vue'));
const TradingContestTag = defineAsyncComponent(() => import('@/components/elements/TradingContestTag.vue'));
const ModalCash = defineAsyncComponent(() => import('@/components/modals/ModalCash'));
const Status = defineAsyncComponent(() => import('@/components/elements/Status'));
const CreateTradingModal = defineAsyncComponent(() => import('@/components/modals/CreateTradingModal'));
const CloseTradingModal = defineAsyncComponent(() => import('@/components/modals/CloseTradingModal'));
const PublishModal = defineAsyncComponent(() => import('@/components/modals/PublishModal'));
const CheckModal = defineAsyncComponent(() => import('@/components/modals/CheckModal'));
const ActionButtons = defineAsyncComponent(() => import('./overview/ActionButtons'));

const ViewPage = {
  name: 'ViewPage',
  components: {
    RecordViewWrapper,
    Main,
    TableWrapper,
    ModalCash,
    Status,
    CreateTradingModal,
    TradingAuth,
    TradingContestTag,
    PublishModal,
    CheckModal,
    ActionButtons,
    CloseTradingModal,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { state, dispatch } = useStore();
    const i18n = useI18n();

    const visibleModal = ref(false);
    const visibleSuccess = ref(false);
    const title = ref('');
    const visibleCreateTradingAccount = ref(false);
    const passwordVisible = ref({ invest: false, master: false });
    const publishModal = ref({ data: null, visible: false });
    const checkModal = ref({ data: null, visible: false });
    const closeTradingModal = ref({ data: null, visible: false });

    const tradingAccountsMeta = computed(() => state.tradingAccounts.meta);
    const isLoading = computed(() => state.tradingAccounts.loading);
    const actionCashType = computed(() => route.params.actionCashType);
    const accId = computed(() => route.params.accId);
    const windowSize = computed(() => window.innerWidth);
    const currentType = ref('');
    const dataSource = computed(() =>
      state.tradingAccounts.data?.map((account, key) => {
        const { id, attributes } = account;
        const { amount, amount_currency, updated_at, contest } = attributes;
        const { status, leverage, name, modificator } = attributes.mt5_account_data;

        return {
          key: key + 1,
          id,
          name,
          status,
          leverage: '1:' + leverage,
          mt5_account_data: attributes.mt5_account_data,
          amount: i18n.n(+amount, 'decimal') + ' ' + amount_currency,
          updated_at: i18n.d(updated_at, 'long'),
          contest,
          modificator,
        };
      }),
    );

    const dataNotSended = () => {
      visibleModal.value = false;
      Modal.error({
        title: i18n.t('messages.accountsError', { actionType: route.params.actionType }),
        onOk() {
          closeModal();
        },
        onCancel() {
          closeModal();
        },
      });
    };

    const changeCreateTradingAccount = (type) => {
      visibleCreateTradingAccount.value = !visibleCreateTradingAccount.value;

      if (typeof type == 'string') {
        dispatch('getTradingAccounts', {
          page: tradingAccountsMeta.value.current,
          per_page: 10,
          type: route.params.type,
        });
        setTimeout(() => {
          dispatch('getTradingAccounts', {
            page: tradingAccountsMeta.value.current,
            per_page: 10,
            type: route.params.type,
          });
        }, 5000);
      }
    };

    const dataSended = () => {
      visibleModal.value = false;
      Modal.success({
        title: i18n.t('messages.accountsSuccessfull', { actionType: i18n.t('accounts.' + route.params.actionType) }),
        onOk() {
          closeModal();
        },
        onCancel() {
          closeModal();
        },
      });
    };
    const closeModal = () => {
      visibleModal.value = false;

      router.push(`/trading-accounts/${route.params.type}`);
    };

    const openModal = ({ id, type }) => {
      visibleModal.value = true;
      router.push(`/trading-accounts/${route.params.type}/trading-${type}/${id}`);
    };

    const onChangePage = (page) => {
      dispatch('getTradingAccounts', { type: route.params.type, page, per_page: 10 });
    };

    const openPublishModal = (data) => {
      const account = state.tradingAccounts.data.find((e) => e.id === data.id);
      if (data.id) {
        publishModal.value = { visible: true, data: { ...account.attributes, ...data } };
      }
    };
    const openModalCheck = (data) => {
      checkModal.value = { visible: true, data: data };
    };

    const columns = computed(() => [
      {
        title: i18n.t('accounts.trading.name'),
        dataIndex: 'name',
        key: 'name',
        slots: { customRender: 'name' },
      },
      {
        title: i18n.t('accounts.trading.authorization'),
        dataIndex: 'authorization',
        key: 'authorization',
        // width: '300px',
        slots: { customRender: 'authorization' },
      },
      {
        title: i18n.t('accounts.amount'),
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: i18n.t('accounts.trading.leverage'),
        dataIndex: 'leverage',
        key: 'leverage',
      },
      {
        title: i18n.t('accounts.trading.status'),
        dataIndex: 'status',
        key: 'status',
        slots: { customRender: 'status' },
      },
      {
        title: i18n.t('accounts.updatedAt'),
        dataIndex: 'updated_at',
        key: 'updated_at',
      },
      {
        title: i18n.t('accounts.trading.action'),
        dataIndex: 'action',
        key: 'action',
        slots: { customRender: 'action' },
      },
    ]);

    watchEffect(() => {
      if (
        route.params.type !== currentType.value &&
        !route.params.actionType &&
        route.params.type &&
        route.name.includes('trading-accounts')
      ) {
        currentType.value = route.params.type;
        dispatch('getTradingAccounts', {
          page: 1,
          per_page: 10,
          type: currentType.value,
        });
      }
    });

    onMounted(() => {
      if (route.params.accId && !visibleModal.value) {
        router.push(`/trading-accounts/${route.params.type}`);
      }
    });
    return {
      i18n,
      visibleSuccess,
      title,
      actionCashType,
      visibleModal,
      isLoading,
      dataSource,
      columns,
      accId,
      visibleCreateTradingAccount,
      windowSize,
      tradingAccountsMeta,
      passwordVisible,
      publishModal,
      checkModal,
      closeTradingModal,
      openPublishModal,
      changeCreateTradingAccount,
      dataSended,
      dataNotSended,
      closeModal,
      openModal,
      onChangePage,
      openModalCheck,
    };
  },
};

export default ViewPage;
</script>
<style scoped lang="scss">
.table-data-view {
  .trading__texts {
    min-width: 300px;
  }
}
</style>
